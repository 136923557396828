import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';  // Ensure your CSS file is linked properly

const Home = () => {
  return (
    <div className="home-container">
      <section id="hero" className="hero-section">
        <h1>Welcome to My Portfolio</h1>
        <p>Software Developer Specializing in Efficiency Tools and Data Management</p>
        <Link to="/projects" className="cta-button">See My Work</Link>
      </section>

      <section id="about" className="about-me-container">
        <div className="profile-image-container">
          <img src="profile.jpg" alt="Profile" className="profile-picture"/>
        </div>
        <div className="about-me-text">
          <h2>About Me</h2>
          <p>With a foundation in software development cultivated through comprehensive training programs and a strong educational background, I bring a robust skill set to the table. Proficient in languages like Python and JavaScript, coupled with expertise in web technologies and cloud platforms such as Django and Azure, I am well-prepared to tackle the challenges of the digital landscape. What drives me in this field is the dynamic nature of technology and the opportunity it presents to solve complex problems. I thrive on the adrenaline of overcoming challenges and making a tangible impact, fueled by my adaptability, problem-solving skills, and commitment to continuous learning. As I embark on new endeavors, I am excited by the opportunity to contribute my skills and expertise to projects that push the boundaries of what's possible in software development.</p>
        </div>
      </section>
      
      <section id="skills" className="skills-section">
        <div className="skills-container">
          <div className="development-skills">
            <h2>Software Development Skills</h2>
            <ul>
              <li>Python, JavaScript - Programming Languages</li>
              <li>Django, HTML, CSS - Web Technologies</li>
              <li>Azure - Cloud Platform</li>
              <li>Git, GitHub - Version Control</li>
              <li>Eclipse, PyCharm, Visual Studio Code - IDEs</li>
            </ul>
          </div>
          <div className="transferable-skills">
            <h2>Transferable Skills</h2>
            <ul>
              <li>Effective communication and collaboration</li>
              <li>Advanced problem-solving</li>
              <li>Punctuality and efficient time management</li>
              <li>Adaptability in dynamic environments</li>
              <li>Proactive learning and development</li>
            </ul>
          </div>
        </div>
      </section>

      <footer className="footer">
        <p>Contact me at:</p>
        <div className="social-links">
          <a href="mailto:pauliusss_s@yahoo.com"><img src="/email-logo.png" alt="Email" /></a>
          <a href="https://www.linkedin.com/in/paulius-spudys-b09826239/" target="_blank" rel="noopener noreferrer"><img src="/linkedin-logo.png" alt="LinkedIn" /></a>
          <a href="https://github.com/PaulSpudys" target="_blank" rel="noopener noreferrer"><img src="/github-logo.png" alt="GitHub" /></a>
        </div>
      </footer>
    </div>
  );
};

export default Home;
