// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Projects from './Projects';
import ProjectDetail from './ProjectDetail'; // Import the new component
import CV from './CV';
import Contact from './Contact';
import './App.css';


function App() {
  console.log("API URL:", process.env.REACT_APP_API_URL); // Example usage of an environment variable

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} /> {/* New route for project details */}
          <Route path="/cv" element={<CV />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
