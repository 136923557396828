// ProjectDetail.js
import React from 'react';
import './ProjectDetail.css'; // Ensure you have a CSS file for project details styling

const projectSections = [
  {
    id: 1,
    imageUrl: "/p1home.jpeg",
    description: "The Fuel Efficiency Calculator features a user-friendly interface designed for easy navigation and data input. The calculator allows users to select a vehicle and input essential details such as the distance driven, the unit of distance (e.g., miles), the amount of fuel used, the fuel price per liter, and the preferred currency. This setup facilitates the precise calculation of fuel efficiency, helping users manage their fuel costs effectively. The design is minimalistic with a focus on functionality, ensuring users can quickly access the information they need without unnecessary distractions. The page also includes a Calculate button in a contrasting green color, making it stand out and easy to click. This tool is an invaluable resource for drivers looking to optimize their fuel consumption and understand their vehicle's performance in monetary terms.",
    // ... more properties if needed
  },
  {
    id: 2,
    imageUrl: "/p1results.jpeg",
    description: "The Fuel Efficiency Results page displays the calculated fuel efficiency for the user's vehicle in a clear and concise manner. It presents the Miles Per Gallon (MPG), the fuel consumption in liters per 100 kilometers (L/100km), and the total fuel cost in currency of your choice. A Calculate Again button is provided for users to easily re-run calculations with different inputs or corrections, enhancing usability and engagement. The design is streamlined and professional, focusing on delivering essential information quickly and efficiently.",
  },
  {
    id: 3,
    imageUrl: "/p1history.jpeg",
    description: "The Calculation History page of the Fuel Efficiency Calculator provides a comprehensive log of all previous fuel efficiency calculations. Each entry includes details such as date, miles driven, gallons used, fuel price, and the calculated miles per gallon (MPG) and liters per 100 kilometers (L/100km). Users can edit or delete records, and the page also displays the calculated average MPG and L/100km across all entries, helping users to track and analyze their fuel usage over time effectively. A New Calculation button allows users to quickly initiate new calculations, enhancing the usability of the application.",
  },
  {
    id: 4,
    imageUrl: "/p1price.jpeg",
    description: "The Fuel Prices page of the Fuel Efficiency Calculator displays current fuel prices from various retailers across different locations. Each listing provides the retailer's name, address, postcode, and the prices for different types of fuel including diesel and different grades of gasoline. Users can search for specific towns or postcodes to find the most up-to-date fuel prices in their area. This page is updated daily at midnight and is part of a UK government scheme, with plans to expand coverage to include fuel prices in Europe and the USA in future versions of the website.",
  },
  {
    id: 5,
    imageUrl: "/p1list.jpeg",
    description: "The Vehicle List feature of the Fuel Efficiency Calculator allows users to manage multiple vehicles by adding them to their profile. Each vehicle's details, including name, make, model, and mileage, can be stored and edited. This functionality enables users to associate all calculation history and fuel usage data with specific vehicles, simplifying the management and tracking of fuel efficiency for each registered vehicle. Users can easily add new vehicles or update and delete existing entries, ensuring their vehicle list remains current and relevant to their needs.",
  },
  // ... more sections
];

const ProjectDetail = () => {
  return (
    <div className="project-detail-container">
      {projectSections.map((section, index) => (
        <div key={section.id} className={`detail-item ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
          <div className="image-container">
            <img src={section.imageUrl} alt={`Section ${section.id}`} />
          </div>
          <div className="text-container">
            <p>{section.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectDetail;
