import React from 'react';

const CV = () => {
    const fileID = '1d295su521AyrYKNDhSzzZZ4xC3JGzVpZ';
    const embedUrl = `https://drive.google.com/file/d/${fileID}/preview`;

    return (
        <div style={{ height: '100vh', overflow: 'auto' }}>
            <iframe
                src={embedUrl}
                title="My CV"
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                allow="autoplay"
            >
                This browser does not support PDFs. Please download the PDF to view it: 
                <a href={`https://drive.google.com/uc?export=download&id=${fileID}`}>Download PDF</a>.
            </iframe>
        </div>
    );
};

export default CV;
