import React, { useState } from 'react';
import './Contact.css';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002'; // Ensure this variable is set in your Vercel environment
        console.log('Attempting to submit to:', `${apiUrl}/send`); // Logging the API URL for debug
        const response = await fetch(`${apiUrl}/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            alert(`Failed to send message: ${response.statusText}`);
            return;
        }

        const data = await response.json();
        alert(data.message); // Display a message on success/failure

        // Clear the form fields after successful submission and user acknowledges the alert
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />

                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />

                <label>Subject:</label>
                <input type="text" name="subject" value={formData.subject} onChange={handleChange} required />

                <label>Message:</label>
                <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>

                <button type="submit">Send</button>
            </form>
            {/* Social media links */}
            <div className="social-links" style={{ marginTop: '20px', textAlign: 'center' }}>
                <a href="mailto:pauliusss_s@yahoo.com"><img src="/email-logo.png" alt="Email" /></a>
                <a href="https://www.linkedin.com/in/paulius-spudys-b09826239/" target="_blank" rel="noopener noreferrer"><img src="/linkedin-logo.png" alt="LinkedIn" /></a>
                <a href="https://github.com/PaulSpudys" target="_blank" rel="noopener noreferrer"><img src="/github-logo.png" alt="GitHub" /></a>
            </div>
        </div>
    );
}

export default Contact;
