import React, { useState } from 'react';
import './Projects.css'; // Make sure this CSS is suited for the new features
import { Link } from 'react-router-dom';

function createMarkup(htmlContent) {
    return { __html: htmlContent };
}

function Projects() {
    const [expandedId, setExpandedId] = useState(null); // State to manage expanded card

    const projectList = [
        {
            id: 1,
            title: "Mileage Calculator App",
            briefDescription: "The Mileage Calculator is an intuitive application designed to efficiently track and calculate mileage and fuel usage. It helps users manage their travel costs with precision and ease.",
            extensiveDescription: `Our Mileage Calculator app is more than just a simple travel tool. It offers a comprehensive suite of features to optimize your driving experience and fuel economy:
                <ul>
                    <li><strong>Mileage Calculation:</strong> Quickly compute the distance traveled using vehicle-specific fuel efficiency metrics, allowing for accurate budgeting and expense tracking.</li>
                    <li><strong>Travel History:</strong> Maintain a detailed log of all past calculations. This feature helps users analyze their travel patterns and fuel usage over time, making it possible to optimize driving habits and routes.</li>
                    <li><strong>Petrol Station Finder:</strong> A built-in search feature that helps users locate nearby petrol stations, providing real-time directions and distance estimates.</li>
                </ul>
                This tool is ideal for anyone from casual drivers to frequent travelers who need to manage their automotive expenses better. Whether you’re planning a road trip or tracking your daily commute, our Mileage Calculator has you covered.`,
            imageUrl: "/1.jpeg",
            readMoreLink: "#",
            liveLink: "https://powerful-beyond-99316-8a9857e6a58c.herokuapp.com/"
        },
        {
            id: 2,
            title: "Discord to Google Sheets Bot",
            briefDescription: "A Discord bot that interacts with Google Sheets to update and manage data based on user commands in specific channels.",
            extensiveDescription: `This Discord bot enhances group interactions by integrating with Google Sheets to manage data via user commands in Discord channels. It is built using Python with discord.py for managing Discord interactions and gspread for Google Sheets operations. Users input commands prefixed with '!' to update or retrieve data from a designated Google Sheet, making this bot ideal for groups needing to manage collaborative data such as attendance, distributions, or scores. 
            The bot includes detailed command parsing, robust error handling to maintain data integrity, and secure operations using environment variables for sensitive information like API tokens and credentials. Designed for scalability and maintainability, this bot can be easily updated or extended with minimal effort, making it a dynamic solution adaptable to various group needs.`,
            imageUrl: "/2.jpeg",
            readMoreLink: "#",
            githubLink: "https://github.com/PaulSpudys/DiscordDkpBot"
        }
    ];

    const toggleExpanded = (id) => {
        setExpandedId(expandedId === id ? null : id); // Toggle expansion
    };

    return (
      <div className="projects-container">
        {projectList.map((project) => (
          <div key={project.id} className="project">
            <img src={project.imageUrl} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3>{project.title}</h3>
              <div dangerouslySetInnerHTML={createMarkup(expandedId === project.id ? project.extensiveDescription : project.briefDescription)} />
              <div className="button-container">
                <button onClick={() => toggleExpanded(project.id)} className="apple-style-button blue-button">
                  {expandedId === project.id ? 'Show Less' : 'Read More'}
                </button>
                {project.id === 1 && (
                  <>
                    <Link to={`/projects/${project.id}`} className="apple-style-button blue-button">
                      Learn More
                    </Link>
                    <a href={project.liveLink} target="_blank" rel="noopener noreferrer" className="apple-style-button white-button">
                      Visit Site
                    </a>
                  </>
                )}
                {project.id === 2 && (
                  <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="apple-style-button white-button">
                    Visit GitHub
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
}

export default Projects;
